import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AddressTaskTypes } from '~/api/types';
import { AddressInputFields } from '~/components/AddTaskModal/utils/addTaskModalUtils';
import {
    getPickupLocationFromPlace,
    getTaskSpecificLocationFromPlace
} from '~/components/AddTaskModal/utils/locationUtils';
import {
    setPickupAddress,
    setPickupServiceTimeMins
} from '~/reducers/addOperationalStopsSlice';

export const useOperationalStopInput = () => {
    const dispatch = useDispatch();

    const [address, setAddress] = useState<Record<string, string | undefined>>(
        {}
    );
    const [initialTaskLocation, setInitialTaskLocation] = useState<{
        lng: undefined | number;
        lat: undefined | number;
    }>({ lng: undefined, lat: undefined });

    useEffect(() => {
        dispatch(setPickupAddress({ ...address }));
    }, [address, dispatch]);

    const updateAddress = useCallback((place: AddressInputFields) => {
        const { latitude, longitude } = place;

        const lng = Number(longitude);
        const lat = Number(latitude);
        setInitialTaskLocation({
            lng,
            lat
        });

        const location = getTaskSpecificLocationFromPlace(
            AddressTaskTypes.PICKUP,
            place
        );

        const obj = getPickupLocationFromPlace(location);
        setAddress(obj);
    }, []);

    const onAddressStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setAddress((prev) => ({ ...prev, pickupAddressStreet: value }));
    };

    const onAddressAptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setAddress((prev) => ({ ...prev, pickupAddressApt: value }));
    };

    const onPickupAddressCityChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.target;
        setAddress((prev) => ({ ...prev, pickupAddressCity: value }));
    };

    const onPickupAddressStateChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.target;
        setAddress((prev) => ({ ...prev, pickupAddressState: value }));
    };

    const onPickupAddressZipCodeChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.target;
        setAddress((prev) => ({ ...prev, pickupAddressZipCode: value }));
    };

    const onOperationalNameChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.target;
        dispatch(setPickupAddress({ pickupCustomerName: value }));
    };

    const onPickupCustomerIdChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.target;
        setAddress((prev) => ({ ...prev, pickupEuid: value }));
    };

    const onPickupServiceTimeMins = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.target;
        dispatch(setPickupServiceTimeMins(value));
    };

    const onPickupLatitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setInitialTaskLocation((prev) => ({
            ...prev,
            lat: value ? Number(value) : undefined
        }));
        setAddress((prev) => ({ ...prev, pickupLatitude: value }));
    };

    const onPickupLongitudeChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.target;
        setInitialTaskLocation((prev) => ({
            ...prev,
            lng: value ? Number(value) : undefined
        }));
        setAddress((prev) => ({ ...prev, pickupLongitude: value }));
    };

    return {
        address,
        updateAddress,
        onAddressStreetChange,
        onAddressAptChange,
        onPickupAddressCityChange,
        onPickupAddressStateChange,
        onPickupAddressZipCodeChange,
        onOperationalNameChange,
        onPickupCustomerIdChange,
        onPickupServiceTimeMins,
        onPickupLatitudeChange,
        onPickupLongitudeChange,
        initialTaskLocation,
        setInitialTaskLocation
    };
};
