import { ApiTask, DeliveryTaskProps, TimeWindow } from '~/api/types';
import { LiveStop } from '~/data-classes';
import { OperationalStopData } from '../../types';
import { replacePickupWithDelivery } from '../replacePickupWithDelivery';
import { getExternalTaskType } from '../getExternalTaskType';

export const getFirstTwoPartApiTask = ({
    pickupStopData,
    deliveryStopData,
    pickupWindow,
    deliveryWindow
}: {
    pickupStopData: LiveStop;
    deliveryStopData: OperationalStopData;
    pickupWindow: TimeWindow[];
    deliveryWindow: TimeWindow[];
}): ApiTask => {
    const pickupStopDataValues = Object.values(pickupStopData)[0];
    const originalTaskId = pickupStopData.taskId;
    const convertedDeliveryStopData =
        replacePickupWithDelivery(deliveryStopData);

    const {
        contact: pickupContact,
        location: { id: pickupLocation },
        props: pickupProps,
        euid: pickupEuid,
        notification,
        routeDate,
        name: pickupName,
        externalTaskType: pickupExternalTaskType,
        sizeByCompartment,
        status,
        locked,
        orderLocked,
        vehicleTypes,
        labels,
        client,
        equipmentId,
        payerOfFreight,
        reservation,
        carKind,
        dropStay,
        emptyOrder,
        deduplicationId,
        consolidationId,
        id,
        _id: underscoreId,
        __v: underscoreV,
        owner
    } = pickupStopDataValues || {};

    const {
        inventory: pickupInventory,
        invoices: pickupInvoices,
        serviceTime: pickupServiceTime,
        priority,
        externalLinks,
        notes,
        weight,
        pairingEligible,
        size
    } = pickupProps || {};

    const {
        pickupLocation: deliveryLocation,
        props: deliveryProps,
        euid: deliveryEuid,
        externalTaskType: deliveryExternalTaskType,
        pickupCustomerName: deliveryCustomerName
    } = deliveryStopData || {};

    const convertedDeliveryProps = replacePickupWithDelivery(deliveryProps);

    const { deliveryInventory, deliveryInvoices, deliveryServiceTime } =
        (convertedDeliveryProps as DeliveryTaskProps) || {};

    const externalTaskType = getExternalTaskType(
        pickupExternalTaskType,
        deliveryExternalTaskType
    );

    return {
        pickupContact,
        deliveryContact: {
            name: deliveryCustomerName || ''
        },
        pickupLocation,
        deliveryLocation,
        props: {
            pickupServiceTime,
            deliveryServiceTime,
            pickupWindow,
            deliveryWindow,
            pickupInventory,
            deliveryInventory,
            originalPickup: pickupStopDataValues,
            originaldelivery: convertedDeliveryStopData,
            pickupInvoices,
            deliveryInvoices,
            priority,
            externalLinks,
            notes,
            weight,
            pairingEligible,
            size,
            pickupTime: '',
            deliveryTime: ''
        },
        originalProps: pickupProps,
        pickupEuid,
        deliveryEuid: deliveryEuid || '',
        originalTaskId,
        notification,
        routeDate,
        name: pickupName,
        externalTaskType,
        sizeByCompartment,
        status,
        locked,
        orderLocked,
        vehicleTypes,
        labels,
        client,
        equipmentId,
        payerOfFreight,
        reservation,
        carKind,
        dropStay,
        emptyOrder,
        deduplicationId,
        consolidationId,
        createdAt: '',
        updatedAt: '',
        id,
        _id: underscoreId,
        __v: underscoreV,
        owner
    };
};
