import {
    ApiLiveDriver,
    ApiLiveStop,
    SocketDriver,
    SocketScheduleItem
} from '~/api/types';
import { LiveDriver } from '~/data-classes';
import { isAssignmentLate } from '~/utils/assignment-utils';

/**
 * Return type internal to this file
 */
type ProcessedSchedule = {
    schedule: ApiLiveStop[];
    numLateExceptions: number;
};

const makeLiveDriverScheduleJSON = (
    webSchedule: SocketScheduleItem[]
): ProcessedSchedule => {
    let resequenceIndex = 1;
    let completedTasksCount = 0;
    return webSchedule.reduce<ProcessedSchedule>(
        (acc, webStop, stopIndex) => {
            const {
                taskLabels: labels,
                inventoryItems: inventory,
                props: {
                    serviceTime,
                    priority,
                    window: timeWindow,
                    size,
                    weight
                },
                stopnumber,
                isDepot,
                isCompleted
            } = webStop;

            const driverStopNumber = isDepot ? stopIndex : resequenceIndex;

            if (!isDepot) {
                resequenceIndex++;
            }
            if (isCompleted && !isDepot) {
                completedTasksCount++;
            }

            const pendingStopsPosition = driverStopNumber - completedTasksCount;
            const liveStop: ApiLiveStop = {
                ...webStop,
                driverStopNumber,
                stopNumber: isDepot ? stopIndex : stopnumber,
                labels,
                serviceTime,
                priority,
                timeWindow,
                size,
                weight,
                stopPosition: pendingStopsPosition,
                hasCompletedTasks: Boolean(completedTasksCount),
                inventory
            };

            const lateExceptions = isAssignmentLate(liveStop.delay) ? 1 : 0;

            return {
                schedule: [...acc.schedule, liveStop],
                numLateExceptions: acc.numLateExceptions + lateExceptions
            };
        },
        {
            schedule: [],
            numLateExceptions: 0
        }
    );
};

export const makeLiveDriver = (webDriver: SocketDriver) => {
    const {
        id: driverId,
        eid,
        client: clientId,
        lastLocationUpdate,
        location: cepLocation,
        endingShift: isEndingShift,
        profile: {
            name: profileName,
            initials: profileInitials,
            firstname: profileFirstName,
            lastname: profileLastName
        },
        vehicle: {
            eid: vehicleEid,
            id: vehicleId,
            type: vehicleType,
            weightCapacity: vehicleWeightCapacity,
            volumeCapacity: vehicleVolumeCapacity
        },
        stats: {
            totalUnits: volumeCapacityUsed,
            totalWeight: weightCapacityUsed,
            activeAssignmentIndex: currentStop,
            totalSch: numStops,
            timeRemaining: { ms: timeRemaining },
            driverHasActiveLocation: active,
            numInventoryItems,
            numConfirmedInventoryItems,
            numInventoryExceptions,
            atRiskWatch: numAtRiskExceptions,
            lateWatch: statsNumLateExceptions,
            isDriverComplete
        },
        schedule: webDriverSchedule
    } = webDriver;

    const { schedule, numLateExceptions: scheduleNumLateExceptions } =
        makeLiveDriverScheduleJSON(webDriverSchedule);

    // late exceptions are no longer compiled upstream once all assignments are completed.
    // derive from schedule when driver is complete
    const numLateExceptions = isDriverComplete
        ? scheduleNumLateExceptions
        : statsNumLateExceptions;

    const liveDriver: ApiLiveDriver = {
        id: driverId,
        clientId,
        routeName: vehicleEid || eid,
        lastLocationUpdate,
        cepLocation,
        latestLocationUpdate: null,
        routeCenter: null,
        profile: {
            name: profileName,
            initials: profileInitials,
            firstname: profileFirstName,
            lastname: profileLastName
        },
        vehicle: {
            id: vehicleId,
            type: vehicleType,
            maxWeight: vehicleWeightCapacity,
            maxVolume: vehicleVolumeCapacity,
            stats: {
                volumeCapacityUsed,
                weightCapacityUsed
            }
        },
        stats: {
            currentStop,
            numStops,
            timeRemaining,
            active,
            numInventoryItems,
            numConfirmedInventoryItems,
            numInventoryExceptions,
            numAtRiskExceptions,
            numLateExceptions,
            isDriverComplete,
            isEndingShift
        },
        schedule
    };

    return new LiveDriver(liveDriver);
};
