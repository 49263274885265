import React, { useMemo } from 'react';
import { DateTime, Duration } from 'luxon';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { RouteCardText } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';
import dateUtils from '~/utils/date-utils';
import { IconSizes } from '~/ui/components/Icon/IconSizes';

import { useFeatureFlag, useFormatTimeToClientTimeZone } from '~/hooks';
import { FeatureFlag } from '~/utils/feature-flags-utils';

interface RouteCardEtaProps {
    stopData: {
        eta?: string;
        arrivalTime?: string;
        completedAt?: string;
        serviceWindow: {
            start: string;
            end: string;
        };
        isUnassigned?: boolean;
        isCompleted?: boolean;
        isInProgress?: boolean;
        isCanceled?: boolean;
        startServiceAt?: string;
        serviceTime?: string;
    };
    icon?: IconName;
    iconColor?: string;
    className?: string;
    'data-testid'?: string;
}

function RouteCardEta({
    stopData,
    icon,
    iconColor,
    className,
    ...extra
}: RouteCardEtaProps) {
    const { t } = useTranslation('common');
    const { formatTimeToClientTimeZone } = useFormatTimeToClientTimeZone();

    const isClientTimezoneFlagEnabled = useFeatureFlag(
        FeatureFlag.CLIENT_TIMEZONE
    );
    const timeWindow = useMemo(() => {
        if (!stopData || stopData.isCanceled) {
            return '';
        }

        const {
            eta,
            arrivalTime,
            completedAt,
            serviceWindow,
            isUnassigned,
            isCompleted,
            isInProgress,
            startServiceAt,
            serviceTime
        } = stopData;

        const parsedServiceWindow = {
            start: formatTimeToClientTimeZone({
                time: serviceWindow?.start
            }),
            end: formatTimeToClientTimeZone({
                time: serviceWindow?.end
            })
        };

        if (isCompleted && completedAt) {
            return t('stopsDrawer:completedTime', {
                timeWindow: dateUtils.getLocalizedTime(completedAt)
            });
        }

        if (isUnassigned && serviceWindow) {
            return t('time.timeWindow', parsedServiceWindow);
        }

        if (isInProgress) {
            const parsedInProgressServiceWindow = {
                start: dateUtils.getLocalizedTime(startServiceAt as string),
                end: DateTime.fromISO(startServiceAt as string)
                    .plus(Duration.fromISO(serviceTime as string))
                    .toFormat('t')
            };

            return t('time.estimatedTimeWindow', parsedInProgressServiceWindow);
        }

        const parsedArrival = dateUtils.getLocalizedTime(
            (isClientTimezoneFlagEnabled ? arrivalTime : eta) as string
        );

        return t('time.estimatedTime', { time: parsedArrival });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [stopData]);

    function getClassName() {
        const defaultClassName = 'routecard-eta';
        return classNames(defaultClassName, className);
    }

    return (
        <RouteCardText
            className={getClassName()}
            icon={icon}
            iconColor={iconColor}
            iconSize={IconSizes.S}
            data-testid={extra['data-testid'] || 'routecard-eta'}
        >
            {timeWindow}
        </RouteCardText>
    );
}

export default RouteCardEta;
