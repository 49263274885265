import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import tasksApi from '~/api/TasksApi';
import { clientTaskToApiTask } from '~/components/AddTaskModal/utils/addTaskModalUtils';
import { LiveStop } from '~/data-classes';
import { useAddTasks, useTaskManualDispatch } from '~/hooks';
import constants from '~/utils/constants';
import {
    TwoPartStopData,
    resetOperationalStopTask,
    selectOperationalStop,
    selectTwoPartStopData,
    setIsOpenOperationalStopModal
} from '~/reducers/addOperationalStopsSlice';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { getAllTasks } from '~/reducers/tasksSlice';
import { addToast } from '~/reducers/toastsSlice';
import {
    getFirstTwoPartApiTask,
    getSecondTwoPartApiTask,
    getStopDataFromLiveStop
} from './utils';
import { addExtraDateToDeliveryWindow } from './utils/addExtraDateToDeliveryWindow';

export const useInsertOperationalStopClick = () => {
    const { t } = useTranslation(['addTask', 'taskManagement']);

    const dispatch = useDispatch();

    const operationalStopTask = useSelector(selectOperationalStop);
    const selectedDate = useSelector(selectDate);
    const selectedDrawerCardId = useSelector(selectSelectedDrawerCardId);
    const serialSelectedLiveDriver = useSelector(
        selectLiveDriverById(selectedDrawerCardId)
    );
    const twoPartStopData = useSelector(selectTwoPartStopData);

    const { addTasks } = useAddTasks();

    const { manualDispatchTask } = useTaskManualDispatch();

    const onSuccessAddTask = useCallback(
        async (successData) => {
            if (twoPartStopData) {
                await tasksApi.unassignTask(
                    twoPartStopData?.pickupStopData.taskId
                );
                await tasksApi.deleteTask(
                    twoPartStopData?.pickupStopData.taskId
                );
            }

            const {
                pickupStopData,
                deliveryStopData: { driverStopNumber }
            } = twoPartStopData as TwoPartStopData;
            const { stopPosition, hasCompletedTasks } =
                pickupStopData as LiveStop;

            const newTask = successData.data.data.success;

            dispatch(
                addToast({
                    message: t('addTaskSuccess'),
                    variant: 'success'
                })
            );

            const driverId = serialSelectedLiveDriver?.id;

            let position = hasCompletedTasks
                ? stopPosition ?? constants.assignmentPosition
                : driverStopNumber;

            const tasksToDispatch = newTask.map((id: string) => {
                const task = {
                    id,
                    position
                };
                position += constants.assignmentPositionIncrement;
                return task;
            });

            await manualDispatchTask(
                {
                    driverId,
                    tasksToDispatch
                },
                {
                    onSuccess: () => {
                        dispatch(
                            addToast({
                                message: t(
                                    'taskManagement:assignTask.dispatchConfirmation',
                                    {
                                        count: newTask.length
                                    }
                                ),
                                variant: 'info'
                            })
                        );
                        dispatch(
                            getAllTasks({
                                routeDate: selectedDate
                            })
                        );
                    },
                    onError: () => {
                        dispatch(
                            addToast({
                                message: t('error:assignTasksError'),
                                variant: 'error'
                            })
                        );
                    }
                }
            );

            dispatch(resetOperationalStopTask());
            dispatch(setIsOpenOperationalStopModal(false));
        },
        [
            dispatch,
            selectedDate,
            serialSelectedLiveDriver,
            t,
            manualDispatchTask,
            twoPartStopData
        ]
    );

    const onErrorAddTask = useCallback(() => {
        dispatch(
            addToast({
                message: t('addTaskFailure'),
                variant: 'error'
            })
        );
    }, [dispatch, t]);

    const handleAddTask = useCallback(
        async (operationalStopData) => {
            const { pickupStopData, deliveryStopData } =
                twoPartStopData as TwoPartStopData;

            const { timeWindow: pickupWindow } = getStopDataFromLiveStop(
                pickupStopData as LiveStop
            );
            const { timeWindow: deliveryWindow } = getStopDataFromLiveStop(
                deliveryStopData as LiveStop
            );

            const updatedDeliveryWindow = addExtraDateToDeliveryWindow(
                pickupWindow,
                deliveryWindow
            );

            const firstTwoPartApiTask = getFirstTwoPartApiTask({
                pickupStopData: pickupStopData as LiveStop,
                deliveryStopData: operationalStopData,
                pickupWindow,
                deliveryWindow: updatedDeliveryWindow
            });
            const secondTwoPartApiTask = getSecondTwoPartApiTask({
                pickupStopData: operationalStopData,
                deliveryStopData: deliveryStopData as LiveStop,
                pickupWindow,
                deliveryWindow: updatedDeliveryWindow
            });

            try {
                const response = await addTasks([
                    firstTwoPartApiTask,
                    secondTwoPartApiTask
                ]);
                onSuccessAddTask(response);
            } catch (error) {
                console.error(error);
                onErrorAddTask();
            }
        },
        [addTasks, onErrorAddTask, onSuccessAddTask, twoPartStopData]
    );

    const onInsertOperationalStopClick = useCallback(() => {
        try {
            const clientTask = {
                ...operationalStopTask,
                date: new Date(operationalStopTask.date),
                deliveryTimeWindows: [
                    {
                        start: new Date(
                            operationalStopTask.deliveryTimeWindows[0].start
                        ),
                        end: new Date(
                            operationalStopTask.deliveryTimeWindows[0].end
                        )
                    }
                ],
                pickupTimeWindows: [
                    {
                        start: new Date(
                            operationalStopTask.pickupTimeWindows[0].start
                        ),
                        end: new Date(
                            operationalStopTask.pickupTimeWindows[0].end
                        )
                    }
                ]
            };
            const apiTask = clientTaskToApiTask(clientTask);
            handleAddTask(apiTask);
        } catch (error) {
            console.error(error);
        }
    }, [operationalStopTask, handleAddTask]);

    const isInvalidForm = useMemo(() => {
        const {
            pickupCustomerName,
            pickupServiceTimeMins,
            pickupAddressStreet,
            pickupAddressCity,
            pickupAddressZipCode,
            pickupLatitude,
            pickupLongitude,
            externalTaskType
        } = operationalStopTask || {};

        return (
            !pickupCustomerName ||
            !pickupServiceTimeMins ||
            !pickupAddressStreet ||
            !pickupAddressCity ||
            !pickupAddressZipCode ||
            !pickupLatitude ||
            !pickupLongitude ||
            !externalTaskType
        );
    }, [operationalStopTask]);

    return {
        onInsertOperationalStopClick,
        isInvalidForm
    };
};
