import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InputLocationMethods } from '~/api/types/ApiTask';
import CustomerMap from '~/components/CustomerManagementPage/CustomerMap';
import { ValidatedInput } from '~/components/ValidatedInput';
import { MapContextProvider } from '~/components/ZoneManagementPage/MapContext';
import { useHereMaps } from '~/hooks';
import { floatValidator } from '~/hooks/useControlledInput';
import { selectOperationalStop } from '~/reducers/addOperationalStopsSlice';
import { Alert, Collapsible, LabelInput, RadioGroup } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import { RadioInputData } from '~/ui/components/RadioGroup/RadioGroup';
import localizationUtils from '~/utils/localization-utils';
import CustomerDatabaseAutocomplete from '../../AddTaskModal/CustomerDatabaseAutocomplete';
import { useOperationalStopInput } from '../useOperationalStopInput';

import '../AddOperationalStopDataContent.scss';

export const inputLocationRadioGroupTestId =
    'addOperationalStopData_inputLocationRadioGroupTestId';
export const inputLocationRadioTestId =
    'addOperationalStopData_inputLocationRadioTestId';
export const viewMapButtonTestId = 'operational-stop-tab-content__map_button';
export const viewMapTestId = 'operational-stop-tab-content__map';
export const addressAptWrapperTestId =
    'addOperationalStopData_addressAptWrapperTestId';
export const addressCityWrapperTestId =
    'addOperationalStopData_addressCityWrapperTestId';
export const addressStateWrapperTestId =
    'addOperationalStopData_addressStateWrapperTestId';
export const addressStreetWrapperTestId =
    'addOperationalStopData_addressStreetWrapperTestId';
export const addressZipCodeWrapperTestId =
    'addOperationalStopData_addressZipCodeWrapperTestId';
export const customerIdWrapperTestId =
    'addOperationalStopData_customerIdWrapperTestId';
export const operationalNameWrapperTestId =
    'addOperationalStopData_operationalNameWrapperTestId';
export const latitudeWrapperTestId =
    'addOperationalStopData_latitudeWrapperTestId';
export const longitudeWrapperTestId =
    'addOperationalStopData_longitudeWrapperTestId';
export const serviceTimeWrapperTestId =
    'addOperationalStopData_ServiceTimeTestId';

export const OperationalStopInput = () => {
    const { t } = useTranslation(['addOperationalStop']);
    const { isHereMapsEnabled } = useHereMaps();
    const {
        address,
        updateAddress,
        onAddressStreetChange,
        onAddressAptChange,
        onPickupAddressCityChange,
        onPickupAddressStateChange,
        onPickupAddressZipCodeChange,
        onOperationalNameChange,
        onPickupCustomerIdChange,
        onPickupServiceTimeMins,
        onPickupLatitudeChange,
        onPickupLongitudeChange,
        initialTaskLocation
    } = useOperationalStopInput();

    const operationalStop = useSelector(selectOperationalStop);
    const { pickupServiceTimeMins, taskType } = operationalStop || {};

    const [inputLocationMethod, setInputLocationMethod] = useState(
        InputLocationMethods.CUSTOMER_DB
    );
    const [parkingLocations] = useState([]);

    const inputLocationRadioData: RadioInputData<InputLocationMethods>[] = [
        {
            id: `${InputLocationMethods.CUSTOMER_DB}`,
            label: t('inputLocation.customerDatabase'),
            value: InputLocationMethods.CUSTOMER_DB
        },
        {
            id: `${InputLocationMethods.MANUAL}`,
            label: t('inputLocation.manual'),
            value: InputLocationMethods.MANUAL
        }
    ];

    function onRadioInputChange(value: InputLocationMethods) {
        setInputLocationMethod(value);
    }

    const englishFormatAddressInputs = [
        <LabelInput
            key="address-line-1"
            className="operational-tab-content__address-line-1 _mt-4"
            label={t('fields.addressStreet.label')}
            placeholder={t('fields.addressStreet.placeholder')}
            required
            data-testid={addressStreetWrapperTestId}
            value={address?.pickupAddressStreet}
            onChange={onAddressStreetChange}
        />,
        <LabelInput
            key="address-line-2"
            className="operational-tab-content__address-line-2"
            placeholder={t('fields.addressApt.placeholder')}
            data-testid={addressAptWrapperTestId}
            value={address?.pickupAddressApt}
            onChange={onAddressAptChange}
        />,
        <LabelInput
            key="city"
            className="operational-tab-content__city _mt-4"
            label={t('fields.city.label')}
            required
            data-testid={addressCityWrapperTestId}
            value={address?.pickupAddressCity}
            onChange={onPickupAddressCityChange}
        />,
        <LabelInput
            key="state"
            className="operational-tab-content__state _mt-4"
            label={t('fields.state.label')}
            data-testid={addressStateWrapperTestId}
            value={address?.pickupAddressState}
            onChange={onPickupAddressStateChange}
        />,
        <LabelInput
            key="zipcode"
            className="operational-tab-content__zipcode _mt-4"
            label={t('fields.zipCode.label')}
            required
            data-testid={addressZipCodeWrapperTestId}
            value={address?.pickupAddressZipCode}
            onChange={onPickupAddressZipCodeChange}
        />
    ];

    const getAddressForm = localizationUtils.formatAddressInputsByLocale(
        englishFormatAddressInputs
    );

    return (
        <>
            <RadioGroup
                radioData={inputLocationRadioData}
                value={inputLocationMethod}
                onChange={onRadioInputChange}
                name="InputLocation"
                label={t('inputLocation.label')}
                required
                className="operational-tab-content__location-radio-group-wrapper _w-100"
                radioInputGroupClassName="operational-tab-content__radio-group"
                radioInputClassName="operational-tab-content__radio-input"
                data-testid={inputLocationRadioGroupTestId}
                radio-testid={inputLocationRadioTestId}
            />
            {inputLocationMethod === InputLocationMethods.CUSTOMER_DB && (
                <CustomerDatabaseAutocomplete
                    placeholder={t(
                        'customerManagement:customerSearch.searchTitle'
                    )}
                    onSelect={updateAddress}
                    taskType={taskType}
                />
            )}
            <LabelInput
                id="OperationalName"
                className="operational-tab-content__operational-name _mt-4"
                label={t('fields.operationalName.label')}
                placeholder={t('fields.operationalName.placeholder')}
                required
                data-testid={operationalNameWrapperTestId}
                value={address?.pickupCustomerName}
                onChange={onOperationalNameChange}
            />
            <LabelInput
                id="customerId"
                className="operational-tab-content__customer-id _mt-4"
                label={t('fields.customerId.label')}
                placeholder={t('fields.customerId.placeholder')}
                data-testid={customerIdWrapperTestId}
                value={address?.pickupEuid}
                onChange={onPickupCustomerIdChange}
            />
            <LabelInput
                id="ServiceTime"
                className="operational-tab-content__service-time _mt-4"
                label={t('fields.serviceTime.label')}
                placeholder={t('fields.serviceTime.placeholder')}
                required
                data-testid={serviceTimeWrapperTestId}
                value={pickupServiceTimeMins}
                onChange={onPickupServiceTimeMins}
            />
            {getAddressForm}
            <Collapsible
                className="operational-tab-content__collapsible"
                textClose={t('coordinateCollapsible.text')}
                textOpen={t('coordinateCollapsible.text')}
                iconClose="chevronDown"
                iconOpen="chevronUp"
                iconColor="meteor"
                iconSize={IconSizes.M}
                iconPosition={Collapsible.iconPosition.LEFT}
            >
                <Alert
                    variant="info"
                    icon="infoLine"
                    iconSize={IconSizes.M}
                    iconColor="meteor"
                    className="_ai-center"
                >
                    {t('coordinateCollapsible.alertText')}
                </Alert>
                <div className="operational-tab-content__coordinates _jc-space-between">
                    <ValidatedInput
                        inputComponent={LabelInput}
                        validator={floatValidator}
                        label={t('fields.latitude.label')}
                        required
                        data-testid={latitudeWrapperTestId}
                        value={address?.pickupLatitude}
                        onChange={onPickupLatitudeChange}
                    />
                    <ValidatedInput
                        inputComponent={LabelInput}
                        validator={floatValidator}
                        label={t('fields.longitude.label')}
                        required
                        data-testid={longitudeWrapperTestId}
                        value={address?.pickupLongitude}
                        onChange={onPickupLongitudeChange}
                    />
                </div>
            </Collapsible>
            {!isHereMapsEnabled && (
                <div
                    className="operational-tab-content__map"
                    data-testid={viewMapTestId}
                >
                    <MapContextProvider>
                        <CustomerMap
                            onUpdateCustomerLocation
                            customerLocation={initialTaskLocation}
                            parkingLocations={parkingLocations}
                            isGeocodeEditable
                            geofenceCircleRadius={null}
                            geofencePolygon={null}
                            isDisableCircularGeofences
                        />
                    </MapContextProvider>
                </div>
            )}
        </>
    );
};
